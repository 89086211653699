body {
  margin: 0;
  background-color: #f9f9f9;
  font-family: 'IBM Plex Sans' -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body *,
*::before,
*::after {
  box-sizing: initial;
}

body #app-root {
  min-width: 1200px;
}
body #app-root a {
  text-decoration: none;
}
body #app-root a:hover {
  text-decoration: none;
}

a:-webkit-any-link {
  text-decoration: none;
  color: unset;
  cursor: pointer;
}
a:-webkit-any-link:hover {
  text-decoration: none;
  color: unset;
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
::placeholder {
  color: #373c3f !important;
  opacity: 1 !important; /* Firefox */
}
